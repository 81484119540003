<template>
  <app-list-view
    server-side
    app="expense"
    model="expense"
    api-url="expense/expense/"
    :title="$t('menu.expense')"
    :createTo="{ name: 'expenseCreate' }"
    :editTo="{ name: 'expenseEdit' }"
    :headers="headers"
  />
</template>

<script>
import AppListView from '@components/AppListView'

export default {
  name: 'expenseCategoryList',
  components: {
    AppListView
  },
  computed: {
    headers() {
      return [
        { text: this.$t('fields.company'), value: 'company.name' },
        { text: this.$t('fields.expenseName'), value: 'name' },
        {
          text: this.$t('fields.expenseCategory'),
          value: 'expense_category.name'
        },
        { text: this.$t('fields.expenseType'), value: 'expense_type.name' },
        {
          text: this.$t('fields.expenseGroup'),
          value: 'expense_group.name'
        },
        { text: this.$t('fields.date'), value: 'date', isDate: true },
        { text: this.$t('fields.state'), value: 'state' },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          groupable: false,
          hideFilter: true
        }
      ]
    }
  },
  created() {
    this.$store.dispatch('addBreadcrumb', {
      name: 'expense'
    })
  }
}
</script>
